if ( typeof window.glob !== 'object' ) {

	const glob = { prop: { registered: {}, status: {} } };
	if ( true) {
		const isDevelop = String(window.location.port).startsWith("30");
		const proto = window.location.protocol;
		const host = window.location.host;
		glob.prop.uri = { server: `${proto}//${ isDevelop ? "infocontenuti.lan.crossbow.it" : host }/` };
		glob.prop.uri.root = isDevelop ? "/" : glob.prop.uri.server;
		glob.prop.uri.site = glob.prop.uri.root + (isDevelop ? "" : "ic/esami/");
		glob.prop.uri.api = glob.prop.uri.server + 'ic/pub/ws/';
		//glob.prop.uri.api = 'https://infocontenuti.uniroma3.it/ic/pub/ws/';
		glob.prop.appRootNode = document.getElementById("appRootNode");
		glob.prop.keyApplicationName = "pub-esami";
		glob.prop.homePage = {name: "Home"};
	}

	glob.generateUid = ( pfx ) => {
		if ( typeof pfx !== "string" || ! pfx.length ) pfx = 'Unk';
		return pfx+"-"+("10000000-1048-4000-8000-1400800110084").replace(/[018]/g, c =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	};

	glob.register = ( contexts, trigger, uid ) => {
		if ( typeof contexts === 'string' ) contexts = [contexts];
		if ( typeof trigger !== 'function' ) {
			glob.unregister(contexts,uid);
			return;
		}
		if ( typeof uid !== 'string' ) uid = glob.generateUid();
		if ( Array.isArray(contexts) ) contexts.forEach( c => {
			try {
				if ( typeof glob.prop.registered[c] === 'undefined') glob.prop.registered[c] = {};
				glob.prop.registered[c][uid] = trigger;
			} catch(e) {
				console.log("glob.register", e, contexts, uid, trigger );
			}
		});
		return ()=>{ glob.unregister(contexts, uid); };
	};
	glob.unregister = ( contexts, uid ) => {
		if ( typeof uid !== 'string' ) return;
		if ( typeof contexts === 'string' ) contexts = [contexts];
		if ( Array.isArray(contexts) ) contexts.forEach( c => {
			try {
				if ( typeof glob.prop.registered[c] === 'object') delete glob.prop.registered[c][uid];
			} catch(e) {
				console.log("glob.unregister", e, uid, contexts );
			}
		});
	};
	glob.trigger = async ( context, ...args ) => {
		if ( typeof context !== 'string' ) return;
		// console.log(context,args,glob.prop.registered[context]);
		if ( glob.prop.registered[context] ) Object.entries(glob.prop.registered[context]).forEach(([uid,trigger])=>{
			if ( typeof trigger === 'function' ) setTimeout( ()=>{ trigger.apply(undefined, args); }, 10);
		});
	};
	glob.getStatus = ( s ) => { return glob.prop.status[s]; };
	glob.setStatus = ( s, v, data, data2 ) => {
		glob.prop.status[s] = v;
		glob.trigger( s, v, data, data2 );
	};
	glob.dropStatus = ( s, broadcast ) => {
		if ( typeof s === "string" ) s = [s];
		if ( ! Array.isArray(s) ) return;
		s.forEach( x =>{
			delete glob.prop.status[x];
			if ( broadcast ) glob.trigger(x);
			delete glob.prop.registered[x];
		})
	};

	glob.listEnti = () => {
		return new Promise( (resolve, reject) => {
			if ( glob.prop.enti ) return resolve( JSON.parse(JSON.stringify(glob.prop.enti)));
			if ( glob.prop.loadingEnti ) {
				setTimeout(  ()=>{
						glob.listEnti().then( enti => {
							resolve(enti);
						}).catch( e => {
							reject(e);
						});
				}, 500);
				return;
			}
			glob.prop.loadingEnti = true;
			glob.of.assoEntiOf().then( asso => {
				const uri = glob.prop.uri.api+"enti.js";
				window.fetch(
					uri,
					{
						method: 'GET',
						mode: 'no-cors',
						cache: 'no-cache',
						headers: {
							'pragma': 'no-cache',
							'cache-control': 'no-cache'
						}
					}
				).then( response => {
					if ( ! response.ok ) throw response;
					return response.json();
				}).then( jdata => {
					if ( ! Array.isArray( jdata?.arrayitem )) throw jdata;
					glob.prop.entiById = {};
					glob.prop.enti = jdata.arrayitem
						.filter( x => ( parseInt(x?.kpublic) === 1 && parseInt(x?.kparent) > 1 ) )
						.filter( x => ( asso.kente[parseInt(x.kente)]) )
						.map( e => {
							const n = {
								kente: parseInt(e.kente),
								kparent: parseInt(e.kparent),
								kpreno: parseInt(e.kpreno),
								lnome: e.lnome,
								ldesc: e.ldesc
							};
							let m = e?.lemail;
							if ( m ) m = m.split(' ').find( x => x.includes('@'));
							if ( m ) n.email = m;
							glob.prop.entiById[ n.kente ] = n;
							return n;
						})
						.sort( (a,b)=>( a.ldesc > b.ldesc ? 1 : -1 ));
					delete glob.prop.loadingEnti;
					resolve( JSON.parse(JSON.stringify(glob.prop.enti)));
				}).catch( e => {
					delete glob.prop.loadingEnti;
					console.log(e);
					reject( e );
				});
			}).catch( e => {
				reject(e);
			});
		});
	}

	glob.prenotazioni = ( lente ) => {
		if ( typeof lente === "undefined" ) lente = glob.getCurrentPageParam('corso');
		if ( ! glob.prop.prenotazioni ) glob.prop.prenotazioni = {};
		const d = new Date();
		Object.keys( glob.prop.prenotazioni ).forEach( l => {
			if ( glob.prop.prenotazioni[l].expire < d ) delete glob.prop.prenotazioni[l];
		});
		return new Promise( (resolve, reject)=>{
			if ( glob.prop.prenotazioni[lente] )
				return resolve(JSON.parse(JSON.stringify(glob.prop.prenotazioni[lente].data)));
			const uri = `${ glob.prop.uri.api }preno-bylente${ lente }.js`;
			window.fetch(
				uri,
				{
					method: 'GET',
					mode: 'no-cors',
					cache: 'no-cache',
					headers: {
						'pragma': 'no-cache',
						'cache-control': 'no-cache'
					}
				}
			).then( response => {
				if ( ! response.ok ) throw response;
				return response.json();
			}).then( jdata => {
				const orari = [];
				const preno = [];
				const xform = o => {
					if ( ! o.lcanale ) o.lcanale = "-";
					if ( ! o.lclasse ) o.lclasse = "-";
					o.lnome = {
						it: o.lnome||o.lnomeen,
						en: o.lnomeen||o.lnome
					};
					delete o.lnomeen;
					return o;
				}
				if ( jdata?.orari?.arrayitem ) {
					const ai = Array.isArray(jdata.orari.arrayitem) ? jdata.orari.arrayitem : [jdata.orari.arrayitem];
					ai.forEach( o => { orari.push( xform(o)); });
				}
				if ( jdata?.prenotazioni?.arrayitem ) {
					const ai = Array.isArray(jdata.prenotazioni.arrayitem) ? jdata.prenotazioni.arrayitem : [jdata.prenotazioni.arrayitem];
					ai.forEach( o => { preno.push( xform(o)); });
				}
				const expDate = new Date();
				expDate.setTime( expDate.getTime() + 6000000 ); // valid for 10'
				glob.prop.prenotazioni[lente] = {
					expire: expDate,
					data: {
						entries: orari.length + preno.length,
						orari: orari,
						prenotazioni: preno
					}
				};
				resolve(JSON.parse(JSON.stringify(glob.prop.prenotazioni[lente].data)));
			}).catch( e => {
				console.log(e);
				reject( e );
			});
		});
	};

	glob.of = {
		init : () => {
			if ( ! glob.prop.ofInitPerformed ) {
				glob.prop.ofByKente = {};
				glob.prop.lof2kente = {};
				glob.prop.ofInitPerformed = true;
			}
			return true;
		},
		assoEntiOf : () => {
			glob.of.init();
			return new Promise( (resolve, reject) => {
				if ( glob.prop.assoEntiOf ) return resolve( glob.prop.assoEntiOf );
				if ( glob.prop.loadingAssoEntiOf ) {
					setTimeout(  ()=>{
						glob.of.assoEntiOf().then( asso => {
							resolve(asso);
						}).catch( e => {
							reject(e);
						});
					}, 500);
					return;
				}
				glob.prop.loadingAssoEntiOf = true;
				const uri = glob.prop.uri.api+"of-asso.js";
				window.fetch(
					uri,
					{
						method: 'GET',
						mode: 'no-cors',
						cache: 'no-cache',
						headers: {
							'pragma': 'no-cache',
							'cache-control': 'no-cache'
						}
					}
				).then( response => {
					if ( ! response.ok ) throw response;
					return response.json();
				}).then( jdata => {
					if ( ! Array.isArray( jdata?.arrayitem )) throw jdata;
					const asso = { kente: {}, lente: {}};
					jdata.arrayitem
						.filter(a =>( a && a?.kente && parseInt(a.kente) && a.lente ) )
						.forEach( a => {
							const k = parseInt(a.kente);
							const l = a.lente;
							if ( ! asso.kente[k] ) asso.kente[k]=[];
							asso.kente[k].push(l)
							asso.lente[l] = k;
						});
					glob.prop.assoEntiOf = asso;
					delete glob.prop.loadingAssoEntiOf;
					resolve( glob.prop.assoEntiOf );
				}).catch( e => {
					delete glob.prop.loadingAssoEntiOf;
					console.log(e);
					reject( e );
				});
			});
		},
		byKente : ( kente = 0 ) => {
			if (typeof kente !== "number") kente = parseInt(kente);
			return new Promise( (resolve, reject) => {
				if ( isNaN(kente) || ! kente ) resolve(undefined);
				if ( glob.prop.ofByKente && Object.keys(glob.prop.ofByKente).includes(String(kente)) )
					return resolve( JSON.parse( JSON.stringify( glob.prop.ofByKente[String(kente)] )) );
				if ( glob.prop.loadingofByKente || ! glob.prop.ofByKente ) {
					setTimeout(  ()=>{
						glob.of.byKente(kente).then( data => {
							resolve(data);
						}).catch( e => {
							reject(e);
						});
					}, 500);
					return;
				}
				glob.prop.loadingofByKente = true;
				const uri = `${ glob.prop.uri.api }of-list-byente${ kente }.js`;
				window.fetch(
					uri,
					{
						method: 'GET',
						mode: 'no-cors',
						cache: 'no-cache',
						headers: {
							'pragma': 'no-cache',
							'cache-control': 'no-cache'
						}
					}
				).then( response => {
					if ( ! response.ok ) throw response;
					return response.json();
				}).then( jdata => {
					const olddata = jdata?.of?.arrayitem ?
						(Array.isArray( jdata.of.arrayitem ) ? jdata.of.arrayitem : [jdata.of.arrayitem]) :
						[];
					const newdata = olddata
						.filter(a =>( a && a?.lof && a?.kof && parseInt(a.kof) ) )
						.map( a => {
							glob.prop.lof2kente[ a.lof ] = kente;
							const n = {
								kente: kente,
								kof: parseInt(a.kof),
								lof: a.lof,
								lente: a.lente||a.lenteorig,
								lannocorso: a.lannocorso,
								laa: a.laa,
								lcanale: a.lcanale,
								ldocente: a.ldocente,
								lclasse: a.lclasse,
								lmut: a.lmut || undefined,
								lnome: {
									it: a.lnome||a.lnomeen,
									en: a.lnomeen||a.lnome
								},
								lentenome: {
									it: a.lentenome||a.lentenomeorig||a.lentenomeen,
									en: a.lentenomeen||a.lentenome||a.lentenomeorig
								}
							};
							return n;
						});
					glob.prop.ofByKente[String(kente)] = newdata;
					delete glob.prop.loadingofByKente;
					resolve( JSON.parse( JSON.stringify( glob.prop.ofByKente[String(kente)] )) );
				}).catch( e => {
					delete glob.prop.loadingofByKente;
					console.log(e);
					reject( e );
				});
			});
		},
		nome : (ofentry) => {
			const nome = ofentry?.lnome || {};
			return nome[glob.getLanguage()]||nome[glob.getDefaultLanguage()];
		},
		nomeEnte : (ofentry) => {
			const nome = ofentry?.lentenome || {};
			return nome[glob.getLanguage()]||nome[glob.getDefaultLanguage()];
		},
		byLof : async (lof) => {
			const kente = glob.prop.lof2kente(lof);
			if ( ! kente ) return undefined;
			let all;
			try {
				all = await glob.of.byKente(kente);
			} catch (e) {
				return undefined;
			}
			return all.find( o => o.lof === lof );
		},
		kente2lente : async (k) => {
			const asso = await glob.assoEntiOf();
			return asso.kente[k];
		},
		lente2kente : async (l) => {
			const asso = await glob.assoEntiOf();
			return asso.lente[l];
		},
	};

	glob.getLanguage = () => glob.getStatus('applicationLanguage');

	glob.getDefaultLanguage = () => ( glob.prop.labelsDB?.default );

	glob.setLanguage = ( lang ) => {
		if ( glob.prop.labelsDB ) {
			const curlanguage = glob.getStatus("applicationLanguage");
			if ( typeof lang === "undefined" && curlanguage ) lang = curlanguage;
			if ( typeof lang === "undefined" ) {
				const navl = glob.getStorage("applicationLanguage")
				if ( navl ) lang = navl;
			}
			if ( typeof lang === "undefined") {
				const navl = window.navigator.language.substring(0,2).toLowerCase();
				if ( glob.prop.labelsDB[navl] ) lang = navl;
			}
			if ( typeof lang === "undefined") {
				(window.navigator.languages||[]).forEach( cl => {
					if ( typeof lang === "string" ) return;
					const navl = cl.substring(0,2).toLowerCase();
					if ( glob.prop.labelsDB[navl] ) lang = navl;
				});
			}
			if ( lang && ! glob.prop.labelsDB[lang] ) lang = undefined;
			if ( typeof lang === "undefined") {
				const ulangs = (window.navigator.languages||[])
					.filter(x => (x && typeof x === "string") )
					.map( x => x.substring(0,2).toLowerCase() );
				const applangs = Object.keys(glob.prop.labelsDB)
					.filter( x => (x.length === 2) );
				lang = ulangs.find( x => ( applangs.includes(x) ) )||glob.prop.labelsDB.default;
			}
			if ( lang !== curlanguage ) {
				window.document.documentElement.setAttribute('lang',lang);
				glob.setStorage("applicationLanguage",lang);
				glob.setStatus('applicationLanguage',lang);
			}
			return;
		}
		window.fetch(
			glob.prop.uri.site + `media/labels.json`,
			{
				method: 'GET',
				cache: 'no-cache',
				headers: {
					'pragma': 'no-cache',
					'cache-control': 'no-cache',
					'Accept': 'application/json',
				},
				referrerPolicy: 'no-referrer'
			}
		).then( response => {
			if ( ! response.ok ) throw response;
			return response.json();
		}).then( jdata => {
			glob.prop.labelsDB = jdata;
			glob.setLanguage(lang);
		}).catch( e => {
			console.log( e );
		});
	};

	glob.label = ( key, values, lang ) => {
		if (typeof key !== "string" ) return "";
		if ( typeof lang === "undefined" ) lang = glob.getStatus("applicationLanguage");
		if ( typeof lang === "undefined" ) return "";
		if ( ! glob.prop.labelsDB && glob.prop.labelsDB[lang] ) return "";
		let txt = glob.prop.labelsDB[lang][key] ?? glob.prop.labelsDB[glob.prop.labelsDB.default][key];
		if ( typeof txt === "undefined" ) return key;
		if ( values && typeof values === "object" ) {
			Object.entries( values ).forEach( ([k,v])=> {
				const re = new RegExp('\\{\\{'+k+'(\\|[^}]*)?\\}\\}','g');
				txt = txt.replace( re, v );
			});
		}
		txt = txt.replace(/\{\{[^|}]+\|([^}]*)\}\}/g,"$1");
		return txt;
	};

	// -------- Storage ------

	glob.getStorageRepo = () => {
		if ( glob.prop.applicationStorage ) return glob.prop.applicationStorage;
		if ( window.localStorage ) {
			const ls = window.localStorage.getItem(glob.prop.keyApplicationName);
			if (ls) return glob.prop.applicationStorage = JSON.parse(ls);
		}
		if ( window.sessionStorage ) {
			const ls = window.sessionStorage.getItem(glob.prop.keyApplicationName);
			if (ls) return glob.prop.applicationStorage = JSON.parse(ls);
		}
		return glob.prop.applicationStorage = {};
	};

	glob.getStorage = ( item ) => {
		const s = glob.getStorageRepo();
		return s[item];
	};

	glob.setStorage = ( item, value ) => {
		const s = glob.getStorageRepo();
		s[item] = value;
		glob.prop.applicationStorage = s;
		if ( window.localStorage ) {
			window.localStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		} else  if ( window.sessionStorage ) {
			window.sessionStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		}
	}

	// -------- Navigation ------

	glob.getInitialPage = (force) => {
		let op = glob.prop.homePage;
		if (window.location.hash.startsWith("#!/")) {
			const parts = window.location.hash.split("/");
			parts.shift();
			if (parts[0]) {
				op = {'name': parts.shift(), 'params': {}};
				if (parts.length) parts.filter(i => i.includes("=")).forEach(kv => {
					const p = kv.split("=");
					if (p[0]) {
						const k = p.shift();
						const v = p.join("=")||"";
						op.params[k] = decodeURIComponent(v);
					}
				});
			}
		}
		return op;
	};

	glob.object2paramsHash = obj => {
		const parts = [];
		if ( obj && typeof obj === "object") Object.keys(obj)
			.filter(k => !!k)
			.filter(k => ! k.startsWith('_'))
			.sort()
			.forEach(k => {
				let v = obj[k];
				v ??= "";
				parts.push(`${k}=${ encodeURIComponent(v) }`);
			});
		return parts.length ? '/'+parts.join("/") : '';
	};

	glob.setCurrentPage = (op = glob.prop.homePage, force) => {
		if (!op) return;
		if (typeof op === "string") op = {"name": op};
		const newhash = "#!/" + op.name + glob.object2paramsHash(op.params);
		if (newhash !== window.location.hash) {
			window.history.pushState(null, op.name, glob.prop.uri.site + newhash);
			glob.setStatus('mainOperation', op);
		} else if (force) {
			glob.setStatus('mainOperation', op);
		}
	};

	glob.gotoHomePage = () => {
		glob.setCurrentPage()
	};

	glob.setPageByLocation = (force) => {
		glob.setCurrentPage(glob.getInitialPage(), force);
	};

	setTimeout(glob.setPageByLocation, 0);

	window.addEventListener('popstate', () => {
		glob.setPageByLocation(true);
	});

	glob.getCurrentPage = () => {
		let mo = glob.getStatus('mainOperation');
		if (mo) return mo;
		return glob.getInitialPage();
	};

	glob.getCurrentPageParams = () => {
		const mo = glob.getCurrentPage();
		return (mo.params || {});
	};

	glob.getCurrentPageParam = (pname) => {
		const mp = glob.getCurrentPageParams();
		return mp[pname];
	};

	glob.setCurrentPageParams = (params) => {
		const mo = glob.getCurrentPage();
		mo.params = params;
		glob.setCurrentPage(mo);
	};

	glob.setCurrentPageParam = (pname, pvalue) => {
		const params = glob.getCurrentPageParams();
		params[pname] = pvalue;
		glob.setCurrentPageParams(params);
	};

	glob.rmCurrentPageParam = (pname) => {
		const params = glob.getCurrentPageParams();
		if ( Object.keys(params).includes(pname)) {
			delete params[pname];
			glob.setCurrentPageParams(params);
		}
	};

	glob.closeAllDialogs = () => {
		document.querySelectorAll('body > .dialogCurtain, body > .dialogBox').forEach(n => {
			n.remove()
		});
	};

	if (true) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const pageParams = Object.fromEntries(urlSearchParams.entries());
		if (pageParams.debug) glob.prop.data.debug = true;
	}

	window.addEventListener( 'resize', () => {
		if ( glob.prop.timeoutWindowResizing ) window.clearTimeout(glob.prop.timeoutWindowResizing);
		glob.prop.timeoutWindowResizing = window.setTimeout( ()=>{
			delete glob.prop.timeoutWindowResizing;
			glob.trigger('windowResized', { width: parseInt(window.innerWidth), height: parseInt(window.innerHeight)});
		}, 100);
	});

	// -----------------------------------------------------------------------------------------------------------------

	// Code to handle install prompt on desktop
	glob.isRunningInApp = () => {
		if ( typeof glob.prop.isRunningInApp === "boolean" ) return glob.prop.isRunningInApp;
		const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
		if (document.referrer.startsWith('android-app://')) {
			return true;
		} else if (navigator.standalone || isStandalone) {
			return true;
		}
		return false;
	}
	glob.prop.isRunningInApp = glob.isRunningInApp();
	if ( ! glob.prop.isRunningInApp ) {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			if ( window.sessionStorage.getItem('AthsRefused') ) return;
			window.deferredAthsPrompt = e;
			glob.trigger('deferredAthsPrompt',window.deferredAthsPrompt);
		});
	}

	window.glob = glob;
	window.glob.setLanguage();
	window.glob.listEnti();
}
