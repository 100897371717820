import React from 'react';
import progressbar from "../media/progressbar.gif";
import TextLabel from "./TextLabel.mjs";

class IndefiniteProgressbar extends React.Component {
	render(){
		return <img src={progressbar} alt={this.props.alt||<TextLabel value={"LoadInProgress"}/>}/>;
	}
}

export default IndefiniteProgressbar;