import React from "react";
import Filtro from "./Filtro.mjs";
import TextLabel from "./TextLabel.mjs";
import {Container} from "reactstrap";
import "../media/css/FiltersPanel.css";

const filtri = [
	{ k: "lannocorso", label: "Anno di corso" },
	{ k: "lcanale", label: "Canale" },
	{ k: "ldocente", label: "Docente" }
]

const FiltersPanel = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { filtri: {} };
		this.onchange= this.onchange.bind(this);
		this.renderFiltri= this.renderFiltri.bind(this);
		this.uid = window.glob.generateUid("FiltersPanel");
	}
	onchange(k,v) {
		const nf = JSON.parse(JSON.stringify(this.state.filtri));
		if ( v ) nf[k] = v;
		else delete nf[k];
		if ( typeof this.props.onChange === "function" ) this.props.onChange( nf );
		this.setState({ filtri: nf });
	}
	renderFiltri() {
		const out = [];
		filtri.forEach( f => {
			if ( ! this.props.vals[f.k] ) return;
			out.push(
				<Filtro
					key={f.k}
					label={ f.label }
					values={ this.props.vals[f.k] }
					onChange={ (x)=>{ this.onchange( f.k, x ) }}
				/>
			);
		})
		return out;
	};
	render() {
		if ( ! this.props.vals ) return "";
		return(
			<div className={"FiltersPanel"} id={this.uid}>
				<h5>
					{ this.props.totfound
						?
						<TextLabel value={"FiltriFound"} params={{tot:this.props.totfound}} />
						:
						<TextLabel value={"Filtri"} />
					}
				</h5>
				<Container className={"filtersForm"}>
					{ this.renderFiltri() }
				</Container>
			</div>
		)
	}
};

export default FiltersPanel;
