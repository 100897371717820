import React from "react";
import VSwitch from "./VSwitch.mjs";
import TextLabel from "./TextLabel.mjs";

const LanguageSwitcher = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { language: window.glob.getLanguage() }
		this.state.items = [
			{ value:'it', label: <div><big>🇮🇹</big> Italiano</div> },
			{ value:'en', label: <div><big>🇬🇧</big> English</div> }
		];
		this.uid = window.glob.generateUid("LanguageSwitcher");
	}
	componentDidMount() {
		window.glob.register("applicationLanguage", l => {
			this.setState({ language: l });
		},this.uid);
	}
	componentWillUnmount() {
		window.glob.unregister(["applicationLanguage"],this.uid);
	}
	render() {
		if ( ! this.state.language ) return "";
		return(
			<div
				className={"LanguageSwitcher"}
				id={this.uid}
				role={"form"}
				aria-label={ <TextLabel value={"Lingua"} /> }
			>
				<VSwitch
					title={ <TextLabel value={"Lingua"} /> }
					current={ this.state.language }
					items={ this.state.items }
					onChange={ (item)=>{ window.glob.setLanguage( item.value ) }}
				/>
			</div>
		);
	}
}

export default LanguageSwitcher;