import React from "react";
import TextLabel from "../Controls/TextLabel.mjs";

const Orario = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { lang: window.glob.getLanguage() };
		this.renderLine = this.renderLine.bind(this);
		this.uid = window.glob.generateUid("Orario");
	}
	componentDidMount() {
		window.glob.register("applicationLanguage", l=>{
			this.setState({ lang: l });
		}, this.uid);
	}
	componentWillUnmount() {
		window.glob.unregister(["applicationLanguage"], this.uid);
	}
	renderLine( k, label, value ) {
		if ( typeof value === "undefined" ) value = this.props.data[k];
		if ( ! value || value === "-" ) return "";
		return(
			<p key={k}>
				<label>
					<TextLabel value={label} />
				</label>
				{ value }
			</p>
		)
	}
	render() {
		if ( ! this.state.lang ) return "";
		const d = this.props.data;
		return(
			<div className={"Orario"} id={this.uid}>
				<p>
					<strong>
						{ d.lnome[ this.state.lang ] }
					</strong>
				</p>
				{ this.renderLine('lcanale','Canale') }
				{ this.renderLine('ldocente','Docente') }
				{ this.renderLine('lannocorso','Anno di corso') }
				{ this.renderLine('laa','Anno accademico') }
				{ this.renderLine('date','Data',d.tinizio.replace(/ .*/,'') ) }
				{ this.renderLine('time','Ore',d.tinizio.replace(/.+ 0?(.+):..$/,"$1")+" - "+d.tfine.replace(/.+ 0?(.+):..$/,"$1")) }
				{ this.renderLine('laula','Aula') }
				{ this.renderLine('lsede','Sede') }
				{ this.renderLine('lindirizzo','Indirizzo') }
			</div>
		)
	}
}

export default Orario;