import React from "react";
import IndefiniteProgressbar from "../Controls/IndefiniteProgressbar.mjs";
import TextLabel from "../Controls/TextLabel.mjs";
import "../media/css/OfPanel.css";
import FiltersPanel from "../Controls/FiltersPanel.mjs";
import Orari from "./Orari.mjs";

const scanFiltri = data => {
	const filtri = {
		lcanale: {},
		lclasse: {},
		ldocente: {},
		laa: {},
		lannocorso: {}
	};
	const parse = i => {
		Object.keys(filtri).forEach( k => {
			filtri[k][ i[k] ] = 1;
		});
	}
	data.orari.forEach( i => parse(i) );
	data.prenotazioni.forEach(i => parse(i) );
	Object.keys(filtri).forEach( k => {
		const vals = Object.keys( filtri[k] );
		if ( vals.length < 2 ) {
			delete filtri[k];
		} else {
			filtri[k] = vals.sort();
		}
	});
	return Object.keys(filtri).length ? filtri : undefined;
};

const OfPanel = class extends React.Component {
	constructor(prop) {
		super(prop);
		this.state = {
			corso : window.glob.getCurrentPageParam('corso'),
			lang: window.glob.getLanguage()
		};
		this.loadData = this.loadData.bind(this);
		this.uid = window.glob.generateUid("OfPanel");
	}
	componentDidMount() {
		window.glob.register('mainOperation', op => {
			const p = op?.params||{};
			const ns = {};
			if ( p.corso !== this.state.corso ) {
				ns.corso = p.corso;
				ns.data = false;
				ns.empty = false;
				ns.filtri = {};
			}
			if ( Object.keys(ns).length ) this.setState(ns);
		},this.uid);
		window.glob.register("applicationLanguage", l=>{
			this.setState({ lang: l });
		}, this.uid);
		window.glob.register('selectedCorsoData', c => {
			let v = (c?.ldesc ?? c?.label);
			if ( v && typeof v === "string" ) v = v.replace(/\s*▹.*/,"");
			this.setState({ nomecorso: v});
		}, this.uid);
	}
	componentWillUnmount() {
		window.glob.unregister(["applicationLanguage","mainOperation","selectedCorsoData"], this.uid);
	}
	async loadData() {
		let data;
		try {
			data = await window.glob.prenotazioni( this.state.corso );
		} catch (e) {
			setTimeout( ()=>{ this.loadData() }, 1000 );
		}
		if ( ! data.entries ) {
			this.setState({ empty: true });
			return;
		}
		this.setState({
			data: data,
			totfound: (data?.prenotazioni?.length ?? 0) + (data?.orari?.length ?? 0),
			filtridef: scanFiltri(data)
		});
	}
	render() {
		if ( ! (this.state.corso && this.state.lang ) ) return "" ;
		if ( this.state.empty ) {
			const params = {};
			if ( this.state.nomecorso ) params.corso = `“${this.state.nomecorso}”`;
			const m = window.glob.getStatus('selectedEnteData')?.email;
			return(
				<div className={"OfPanel error"} id={this.uid}>
					<p>
						<TextLabel value={"CourseEmpty"} params={params} />
					</p>
					<p>
						<TextLabel value={"ContattareSegreteria"} />
						{ m ? (<>: <a className={"icon"} href={"mailto:"+m}>✉️</a></>) : "."}
					</p>
				</div>
			);
		}
		if ( ! this.state.data ) {
			setTimeout( ()=>{ this.loadData() }, 1 );
			return (
				<div className={"OfPanel loading"} id={this.uid}>
					<IndefiniteProgressbar/>
				</div>
			);
		}
		//console.log( this.state );
		return(
			<div className={"OfPanel"} id={this.uid}>
				<FiltersPanel
					totfound={ this.state.totfound }
					vals={ this.state.filtridef }
					onChange={ f => { this.setState({filtri: f})} }
				/>
				<Orari
					totfound={ this.state.totfound }
					key={ "filtri:"+this.state.corso+Object.entries(this.state.filtri||{}).map( ([k,v])=>(k+v)) }
					data={ this.state.data }
					filtri={ this.state.filtri }
				/>
			</div>
		);
	}
}

export default OfPanel;
