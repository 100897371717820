import React from "react";
import {Row,Col,Container} from "reactstrap";
import TextLabel from "../Controls/TextLabel.mjs";
import logo from "../media/logo-uniroma3.png";
import "../media/css/PageHeader.css";
import LanguageSwitcher from "../Controls/LanguageSwitcher.mjs";
import ViewModeSwitcher from "../Controls/ViewModeSwitcher.mjs";

const gotohomepage = () => {
	const p = window.glob.getCurrentPageParams();
	const np = {
		name: 'Home',
		params: {}
	};
	if ( p.ente && p.fixedente ) {
		np.params.ente = p.ente;
		np.params.fixedente = p.fixedente;
	} else {
		window.glob.setStatus('selectedEnteData');
	}
	window.glob.setCurrentPage(np);
	window.glob.setStatus('selectedCorsoData');
};

const PageHeader = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.uid = window.glob.generateUid("PageHeader");
	}
	gotohomepage() {
		const p = window.glob.getCurrentPageParams();
		const np = {
			name: 'Home',
			params: {}
		};
		if ( p.ente && p.fixedente ) {
			np.params.ente = p.ente;
			np.params.fixedente = p.fixedente;
		}
		window.glob.setCurrentPage(np);
	}
	render() {
		return (
			<div className={"PageHeader"} id={this.uid}>
				<div
					className={"controlsSwitch"}
					onClick={ ()=>{ document.getElementById(this.uid).classList.toggle("showControls"); } }
				>
					☰
				</div>
				<Container fluid={true}>
					<Row>
						<Col className={"logo"}>
							<img src={logo} alt={"UniRoma3"} onClick={ gotohomepage } />
						</Col>
						<Col className={"title"} key={"Title"}>
							<TextLabel value="PageMainTitle"/>
						</Col>
						<Col className={"controls language"}>
							<LanguageSwitcher />
						</Col>
						<Col className={"controls viewmode"}>
							<ViewModeSwitcher />
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default PageHeader;