import React from "react";
import {Col, Row} from "reactstrap";
import TextLabel from "./TextLabel.mjs";
import Select from "react-select";

const Filtro = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: (props.values||[]).map( x => { return {value: x, label: x} })
		}
		this.onchange= this.onchange.bind(this);
		this.uid = window.glob.generateUid("Filtro");
	}
	onchange(o) {
		if ( typeof this.props.onChange === "function" ) this.props.onChange( o?.value ?? o );
	}
	render() {
		return(
			<Row>
				<Col className={"label"} md={2}>
					<label>
						<TextLabel value={this.props.label} />
					</label>
				</Col>
				<Col className={"field"} md={10}>
					<Select
						isClearable={true}
						id={this.uid}
						onChange={ (s)=> { this.onchange(s) } }
						placeholder={ window.glob.label('SelectFilter') }
						options={ this.state.options }
					/>
				</Col>
			</Row>
		)
	}
};

export default Filtro;
