import React from 'react';
import Select from "react-select";
import IndefiniteProgressbar from "./IndefiniteProgressbar.mjs";
import TextLabel from "./TextLabel.mjs";

const maxShownLen = 120;

class SelectCorso extends React.Component {
	constructor(opts) {
		super(opts);
		this.state = {
			param: 'corso',
			lang: window.glob.getLanguage()
		};
		const ente = window.glob.getCurrentPageParam('ente');
		if ( ente && ! isNaN(parseInt(ente))) {
			this.state.ente = parseInt(ente);
			const sel = window.glob.getCurrentPageParam(this.state.param);
			if ( sel ) {
				this.state.selected = sel;
			}
		}
		this.loadOf = this.loadOf.bind(this);
		this.of2options = this.of2options.bind(this);
		this.onchange = this.onchange.bind(this);
		this.uid = window.glob.generateUid("SelectCorso");
	};
	componentDidMount() {
		window.glob.register('mainOperation', op => {
			const p = op?.params||{};
			const ente = parseInt(p.ente);
			if ( ente !== this.state.ente || ( this.state.opts && ! p[this.state.param] )) {
				const ns = {
					ente: ente,
					opts: false,
					selected: false
				};
				this.setState(ns);
				if ( p[this.state.param] ) window.glob.rmCurrentPageParam( this.state.param );
			}
		},this.uid);
		window.glob.register('applicationLanguage', l => {
			this.setState({ lang: l, opts: false });
		});
	}
	componentWillUnmount() {
		window.glob.unregister(['mainOperation','applicationLanguage'],this.uid);
	}
	async loadOf() {
		let data = await window.glob.of.byKente( this.state.ente );
		if ( data ) {
			this.of2options(data);
			return;
		}
	};
	of2options(data) {
		const opts = [];
		const label = e => {
			let t = window.glob.of.nomeEnte(e);
			if ( t.length > maxShownLen ) t = t.substring(0,maxShownLen).replace(/[^a-z]+[a-z]*$/i,"…");
			const c = e.lclasse ? e.lclasse.replace(/ *[,;.] */g,", ").trim() : false;
			if ( c ) t += ' ▹ '+c;
			return t;
		};
		data.forEach( e => {
			const v = e.lente;
			if ( ! opts.find( x => ( x.value === v )) ) {
				opts.push({
					label: label(e),
					value: v
				});
			}
		});
		const ns = { opts: opts.sort( (a,b)=>( a.label > b.label ? 1 : -1 )) };
		const so = window.glob.getCurrentPageParam(this.state.param);
		if ( so ) {
			ns.selected = so;
			if ( so && opts.length) {
				window.glob.setStatus('selectedCorsoData',opts.find( x => (x.value === so)));
			}
		} else if ( opts.length === 1 ) {
			ns.selected = opts[0].value;
		}
		this.setState(ns);
	}
	onchange(s) {
		if ( s?.value ) {
			window.glob.setCurrentPageParam(this.state.param,s?.value);
			window.glob.setStatus('selectedCorsoData',s);
		} else {
			window.glob.rmCurrentPageParam(this.state.param);
			window.glob.setStatus('selectedCorsoData');
		}
	}
	render() {
		if ( ! this.state.ente ) {
			return <p className={"comment"}><TextLabel value={"SelectEntityFirst"}/></p>;
		}
		if ( ! this.state.opts ) {
			setTimeout( ()=>{ this.loadOf() }, 1);
			return <p className={"comment"}><IndefiniteProgressbar /></p>;
		}
		if ( ! this.state.opts.length ) {
			window.glob.rmCurrentPageParam(this.state.param);
			window.glob.setStatus('selectedCorsoData');
			const e = window.glob.getStatus('selectedEnte')?.label;
			const p = e ? { ente: `“${e}”` } : undefined;
			return(
				<div>
					<p className={"comment"}><TextLabel value={"CourseNotFound"} params={p}/></p>
					<p className={"comment"}><TextLabel value={"ContattareSegreteria"} params={p}/></p>
				</div>
			);
		}
		if ( this.state.opts.length === 1 ) {
			window.glob.setCurrentPageParam(this.state.param,this.state.opts[0].value);
			window.glob.setStatus('selectedCorsoData',this.state.opts[0]);
			return <p className={"comment"}>{ this.state.opts[0].label }</p>;
		}
		const cursel = this.state.selected ? this.state.opts.find( e => ( e.value === this.state.selected)) : undefined;
		return (
			<Select
				id={this.uid}
				isClearable={true}
				options={this.state.opts}
				className={"SelectCorso w-100"}
				autoFocus={ ! cursel }
				defaultValue={ cursel }
				onChange={ (s)=> { this.onchange(s) }}
				placeholder={ window.glob.label('SelectCourse') }
				isDisabled={!this.state.opts}
			/>
		);
	}
}

export default SelectCorso;