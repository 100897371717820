import React from "react";
import VSwitch from "./VSwitch.mjs";
import TextLabel from "./TextLabel.mjs";

const ViewModeSwitcher = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { body: window.document.getElementsByTagName('body')[0] };
		const curHr = this.state.body.classList.contains('highReadability');
		const storedHr = window.glob.getStorage('highReadability');
		const hr = storedHr ?? curHr;
		if ( curHr !== hr ) this.state.body.classList.toggle('highReadability', hr);
		if ( storedHr !== hr ) window.glob.setStorage('highReadability', hr);
		this.state.mode = hr ? 'on' : 'off';
		this.uid = window.glob.generateUid("ViewModeSwitcher");
		this.changed = this.changed.bind(this);
	}
	changed( status ) {
		const active = status === 'on';
		this.state.body.classList.toggle('highReadability', active);
		window.glob.setStorage('highReadability', active);
		this.setState({ mode: active ? 'on' : 'off' });
	}
	render() {
		return(
			<div
				className={"ViewModeSwitcher"}
				id={this.uid}
				role={"form"}
				aria-label={ <TextLabel value={"ViewMode"} /> }
			>
				<VSwitch
					title={ <TextLabel value={"ViewMode"} /> }
					current={ this.state.mode }
					items={ [
						{ value: 'off', label: "ViewModeCompact", className: 'alwaysCompact',localize: true },
						{ value: 'on', label: "ViewModeHr", className: 'alwaysHighReadability', localize: true }
					] }
					onChange={ (item)=>{ this.changed( item.value ) }}
				/>
			</div>
		);
	}
}

export default ViewModeSwitcher;