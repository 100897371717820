import React from "react";

const TextLabel = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { lang: window.glob.getStatus("applicationLanguage") };
		this.uid = window.glob.generateUid("TextLabel");
	}
	componentDidMount() {
		window.glob.register("applicationLanguage", l=>{
			this.setState({ lang: l });
		}, this.uid);
	}
	componentWillUnmount() {
		window.glob.unregister("applicationLanguage", this.uid);
	}
	render() {
		return window.glob.label(this.props.value, this.props.params, this.state.lang);
	}
}

export default TextLabel;
