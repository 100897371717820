import React from "react";
import Orario from "./Orario.mjs";
import TextLabel from "../Controls/TextLabel.mjs";
import "../media/css/Orari.css";

const match = ( entry = {}, filtri ={} ) => {
	let m = true;
	const ks = Object.keys(filtri);
	while ( ks.length && m ) {
		const k = ks.shift();
		m = (entry[k] === filtri[k]);
	}
	return m;
}

const Orari = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orari: (props?.data?.orari||[]).filter( x => match(x,props.filtri)),
			prenotazioni: (props?.data?.prenotazioni||[]).filter( x => match(x,props.filtri))
		};
		this.state.tot = this.state.orari.length + this.state.prenotazioni.length;
		this.renderOrari = this.renderOrari.bind(this);
		this.renderPrenotazioni = this.renderPrenotazioni.bind(this);
		this.uid = window.glob.generateUid("Orari");
	}
	renderOrari() {
		const qt = this.state.orari.length;
		if ( ! qt ) return "";
		return(
			<div key={"kp"} className={"Sezione Confermati"}>
				<h5><TextLabel value={"Confermati"} /> ({ qt })</h5>
				<div className={"Elenco"}>
					{  this.state.orari.map( (x,idx) => <Orario key={idx} data={x} /> ) }
				</div>
			</div>
		);
	}
	renderPrenotazioni() {
		const qt = this.state.prenotazioni.length;
		if ( ! qt ) return "";
		return(
			<div key={"ko"} className={"Sezione Prenotati"}>
				<h5><TextLabel value={"Prenotati"} /> ({ qt })</h5>
				<div className={"Elenco"}>
					{  this.state.prenotazioni.map( (x,idx) => <Orario key={idx} data={x} /> ) }
				</div>
			</div>
		);
	}
	render() {
		return(
			<div className={"Orari"} id={"uid"}>
				{ this.state.tot ? "" : <p align={"center"}><TextLabel value={"NoMatch"}/>.</p> }
				{ this.renderOrari() }
				{ this.renderPrenotazioni() }
			</div>
		)
	}
}

export default Orari;