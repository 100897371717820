import React from "react";
import './media/css/App.css';
import PageHeader from "./Components/PageHeader.mjs";
import Home from "./Components/Home.mjs";
import PageToolbar from "./Components/PageToolbar.mjs";
import AddToHomeScreen from "./Components/AddToHomeScreen.mjs";

const App = class extends React.Component {
	constructor(prop) {
		super(prop);
		this.state = {};
		this.state = { mainOperation: window.glob.getInitialPage() };
		this.renderContent = this.renderContent.bind(this);
		this.uid = window.glob.generateUid("App");
	}
	renderContent( mo = {} ) {
		switch (mo.name) {
			default: return <Home params={mo.params} />;
		}
	}
	render() {
		return(
			<>
				<div className={"App"} id={this.uid}>
					<PageHeader />
					<PageToolbar />
					<div className={"AppContent"}>
						{ this.renderContent( this.state.mainOperation ) }
					</div>
				</div>
				<AddToHomeScreen/>
			</>
		);
	}
}

export default App;
