import React from 'react';
import Select from "react-select";
import IndefiniteProgressbar from "./IndefiniteProgressbar.mjs";

const broadcast = async (kente) => {
	let sel;
	if ( kente ) {
		kente = parseInt(kente);
		if ( kente && ! isNaN(kente) ) {
			sel = ( await window.glob.listEnti() ).find( x => ( x.kente === kente) );
		}
	}
	if ( sel ) {
		window.glob.setCurrentPageParam('ente', kente);
		window.glob.setStatus('selectedEnte', {value: kente, label: sel.ldesc});
		window.glob.setStatus('selectedEnteData', sel);
	} else {
		window.glob.rmCurrentPageParam('corso');
		window.glob.rmCurrentPageParam('ente');
		window.glob.setStatus('selectedEnte');
		window.glob.setStatus('selectedEnteData');
	}
};

class SelectEnte extends React.Component {
	constructor(opts) {
		super(opts);
		this.state = {};
		const sel = window.glob.getCurrentPageParam('ente');
		if (sel && !isNaN(parseInt(sel))) {
			this.state.selected = parseInt(sel);
			const fixed = window.glob.getCurrentPageParam('fixedente');
			if (fixed) this.state.fixed = true;
		}
		this.loadEnti = this.loadEnti.bind(this);
		this.enti2options = this.enti2options.bind(this);
		this.onchange = this.onchange.bind(this);
		this.uid = window.glob.generateUid("SelectEnte");
		if (this.state.selected) setTimeout(() => {
			broadcast(this.state.selected)
		}, 100);
	};

	componentDidMount() {
		window.glob.register('mainOperation', op => {
			const p = op?.params || {};
			const ente = p.ente ? parseInt(p.ente) : false;
			if (ente !== this.state.selected) {
				this.setState({selected: ente, fixed: false, enti: false});
			}
		}, this.uid);
	}

	componentWillUnmount() {
		window.glob.unregister(['mainOperation'], this.uid);
	}

	async loadEnti() {
		let el = await window.glob.listEnti();
		if (el) {
			this.enti2options(el);
			return;
		}
	};

	enti2options(enti) {
		let opts = window.glob.getStatus('listaEntiOptions');
		if (!opts) {
			opts = enti.map(e => {
				const o = {label: e.ldesc, value: e.kente};
				return o;
			});
			window.glob.setStatus('listaEntiOptions', opts);
		}
		this.setState({enti: opts});
	}

	async onchange(s) {
		const v = parseInt(s?.value || s);
		if (v && !isNaN(v)) {
			if (v !== this.state.selected) {
				window.glob.rmCurrentPageParam('corso');
				window.glob.setStatus('selectedCorsoData');
				broadcast(v);
			}
		} else {
			broadcast();
		}
	}

	render() {
		if (!this.state.enti) {
			setTimeout(() => {
				this.loadEnti()
			}, 1);
			return <p className={"comment"}><IndefiniteProgressbar/></p>;
		}
		const cursel = this.state.selected ? this.state.enti.find(e => (e.value === this.state.selected)) : undefined;
		if (cursel && this.state.fixed) {
			return <h4>{cursel.label}</h4>;
		}
		return (
			<Select
				id={this.uid}
				isClearable={true}
				options={this.state.enti}
				className={"SelectEnte w-100 " + (this.state.selected ? 'preselected' : '')}
				autoFocus={!this.state.selected}
				defaultValue={cursel}
				onChange={(s) => {
					this.onchange(s)
				}}
				placeholder={ window.glob.label('SelectEntity') }
				isDisabled={!this.state.enti}
			/>
		);
	}
}

export default SelectEnte;