import React from "react";
import "../media/css/Home.css";
import SelectEnte from "../Controls/SelectEnte.mjs";
import {Col, Container, Row} from "reactstrap";
import TextLabel from "../Controls/TextLabel.mjs";
import SelectCorso from "../Controls/SelectCorso.mjs";
import OfPanel from "./OfPanel.mjs";

const Home = class extends React.Component {
	constructor(prop) {
		super(prop);
		const p = window.glob.getCurrentPageParams();
		this.state = {
			lang: window.glob.getLanguage(),
			fixedente: !! p.fixedente,
			ente: p.ente ? parseInt(p.ente) : undefined,
			corso: p.corso ? parseInt(p.corso) : undefined
		};
		this.uid = window.glob.generateUid("Home");
	}
	componentDidMount() {
		window.glob.register('mainOperation', op => {
			const p = op?.params||{};
			const ns = {};
			if ( (!! p.fixedente) !== this.state.fixedente ) ns.fixedente = !! p.fixedente;
			if ( p.corso !== this.state.corso ) ns.corso = p.corso;
			if ( Object.keys(ns).length ) this.setState(ns);
		},this.uid);
		window.glob.register("applicationLanguage", l=>{
			this.setState({ lang: l });
		}, this.uid);
	}
	componentWillUnmount() {
		window.glob.unregister(["applicationLanguage","mainOperation"], this.uid);
	}
	render() {
		if ( ! this.state.lang ) return "";
		return(
			<div className={ "Home" } id={ this.uid } lang={this.state.lang}>
				<Container className={"mainForm"}>
					<Row>
						<Col className={"label"} md={2}>
							{ this.state.fixedente ? "" : <label><TextLabel value={"Ente"}/></label> }
						</Col>
						<Col className={"field"} md={10}>
							<SelectEnte />
						</Col>
					</Row>
					<Row>
						<Col className={"label"} md={2}>
							<label>
								<TextLabel value={"Corso"} />
							</label>
						</Col>
						<Col className={"field"} md={10}>
							<SelectCorso />
						</Col>
					</Row>
				</Container>
				<OfPanel />
			</div>
		);
	}
}

export default Home;
