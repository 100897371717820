import React from "react";
import "../media/css/PageToolbar.css";
import {Button, UncontrolledTooltip} from "reactstrap";
import TextLabel from "../Controls/TextLabel.mjs";

const PageToolbar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookies: !window.glob.getStorage('hideCookiePolicy'),
			ente: (window.glob.getStatus('selectedEnteData') || false),
			corso: (window.glob.getStatus('selectedCorsoData') || false)
		};
		this.renderPath = this.renderPath.bind(this);
		this.cookiePolicy = this.cookiePolicy.bind(this);
		this.uid = window.glob.generateUid("PageToolbar");
	}

	componentDidMount() {
		window.glob.register('selectedEnteData', (e) => {
			this.setState({ente: (e || false)});
		}, this.uid);
		window.glob.register('selectedCorsoData', (e) => {
			this.setState({corso: (e || false)});
		}, this.uid);
	}

	componentWillUnmount() {
		window.glob.unregister(['selectedEnteData', 'selectedCorsoData'], this.uid);
	}

	renderPath() {
		return (
			<div className={"breadcrumbs"}>
				<div>
					{this.state.ente ? (
						<>
							<div className={"ente"}>
								{this.state.ente.ldesc ?? this.state.ente.label}
							</div>
							{
								this.state.corso ? (
									<div className={"corso"}>
										{this.state.corso.ldesc ?? this.state.corso.label}
									</div>
								) : ""
							}
						</>
					) : (
						<div className={"empty"}>
							<TextLabel value={"SelectEntityFirst"}/>
						</div>
					)}
				</div>
			</div>
		);
	}

	cookiePolicy() {
		const btid = window.glob.generateUid("button");
		return (
			<div className={"PageToolbar"} id={this.uid}>
				<div className={"cookie-policy"}>
					<div>
						<div>
							<Button
								color={"light"}
								className={"btn-icon"}
								id={btid}
								onClick={() => {
									window.glob.setStorage('hideCookiePolicy', true);
									this.setState({showCookies: false});
								}}
							>
								<b>
									<TextLabel value={"Nascondi"}/>
								</b>
								<i>✕</i>
							</Button>
							<UncontrolledTooltip
								placement={"left"}
								target={btid}
							>
								<TextLabel value={"CookieInfoNascondi"}/>
							</UncontrolledTooltip>
						</div>
						<h5>
							<TextLabel value={"CookieInfoTitle"}/>
						</h5>
						<p>
							<TextLabel value={"CookieInfo1"}/>
						</p>
						<p>
							<TextLabel value={"CookieInfo2"}/>
						</p>
					</div>
				</div>
				<div className={"hr"}/>
			</div>
		);
	}

	render() {
		return (
			<div className={"PageToolbar"} id={this.uid}>
				<div className={"hr"}/>
				{this.renderPath()}
				{this.state.showCookies ? this.cookiePolicy() : ""}
			</div>
		);
	}
}

export default PageToolbar;